.gate-email-sign-up {
  .form-section {
    // background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    // margin: 1em 0;
    padding: 1em 0;
    line-height: 1.3em;
    .form-header {
      line-height: 1.5em;
      font-size: 1em;
      padding: 0.8em 0.5em;
    }
    .brand-name {
      font-weight: 600
    }
    .subscribe-button {
      background: #00ae00;
      color: white;
      margin-left: 0.4em;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .promo-section {
    position: relative;
    &.disabled {
      opacity: 0.8;
    }
  }
  
  .success {
    text-align: center;
  }
  .click-overlay {
    position: absolute;
    inset: 0; /* shorthand for top/right/bottom/left: 0 */
    // background-image: url(https://afag.imgix.net/pablitos-tacos-burbank/locks-1.png?w=1000&auto=format);
    background-size: 200px ;
    // opacity: 0.5;
    background-position: -20px -20px;
    // border: 2px solid white;
    z-index: 10;
    cursor: not-allowed;
    pointer-events: all;
  }
  .form-section {
    form {
    //  padding: 1em;
      max-width: 500px;
      margin: 0 auto;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
    input {
      margin: 0.5em 0;
    }
    // background: rgba(255, 255, 255, 0.5)
  }
}
  