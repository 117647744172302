
.menu-checker__header {
    /* font-size: 2em; */
    h1 {
        font-size: 1.5em;
        font-weight: 800;
    }
    line-height: 1.5em;
    padding: 1em;

}
.menu-checker {
    h1 {

    }
    font-size: 16px;
    line-height: 1.2em;
}
.menu-checker-category__header {
    /* margin-left: 4.2em; */
    background-color: black;
    color: white;
    /* font-size: 1.1em; */
    padding: 0.5em;
    h3 {
        
        font-weight: 800;
    }

}
.menu-checker-category__description {
    display: none   ;
}
.checker-menu-item {
    .item-header {
        display: flex;
        
    }
    .item-price {
        border: 1px solid gray;
        border-radius: 0.2em ;
        padding: 0 0.2em;
        text-align: right;
        /* min-width: 4em; */
        margin-right: 0.2em
    };
    .content {
        /* display: flex; */
    }
    .checker-photo-container {
        width: 50px;
        display: none   ;
    
    }
    .checker-menu-item__description {
        display: none;
    }
    &:nth-child(2n) {
        
    }
   

    /* border-bottom: 2px solid gray; */
}

.mod-check {
    background-color: rgba(255,2550,255,0.1);
    padding: 0.2em;
    margin-left: 4.2em;
}
.same-mod-group-as {
    margin-left: 5.2em;

}
.link-name {
    margin-left: 0.2em;
    text-decoration: underline;
    font-style: italic;
}
.group-name {
    font-style: italic;
    text-decoration: underline;
    font-weight: 600;
    .type {
        font-weight: 400;
        opacity: 0.8    ;
    }
}
.check-modifiers {
    margin-left: 1em;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* margin-left: 1em; */
    
}

.check-modifier {
    .price {
        color: lightgreen;
        font-weight: 600;
        margin-left: 0.2em;
    }
    text-transform: lowercase;
    
    margin-right: 0.2em;
    border: 1px solid gray;
    border-radius: 0.2em;
    padding: 0 0.2em;
    &:nth-child(2n) {
        /* background-color: rgb(0,0,0); */
    }
}